import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { WidgetFC } from '../../common/Widget/types';
import { getQuiltWrapperId } from '../../pageeditor/PageEditorQuiltWrapperDuplicateModal/useQuiltWrapperToDuplicate';
import type { BreadcrumbProps } from './Breadcrumb';
import Breadcrumb from './Breadcrumb';
import localStyles from './Breadcrumb.module.pcss';
import type { BreadcrumbWidgetProps } from './types';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import { useEffectOnce } from 'react-use';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import useTranslation from '../../useTranslation';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import EditableWidget from '../../common/Widget/EditableWidget';

const BreadcrumbMock = dynamic(() => import('./BreadcrumbMock'));

export interface PageHeaderBreadcrumbProps extends BreadcrumbProps {
  /**
   * Whether to render the Breadcrumb
   */
  useBreadcrumb?: boolean;
  /**
   * Whether the Breadcrumb is transparent
   */
  useTransparentBreadcrumb?: boolean;
  /**
   * Whether to render the Breadcrumb below the NodeHeader. Defaults to false
   */
  renderBreadcrumbLast?: boolean;
}

export const defaultBreadcrumbWidgetProps: BreadcrumbWidgetProps = {
  linkTextColor: CommonColorCssVariables.GRAY_700,
  linkHighlightColor: CommonColorCssVariables.GRAY_600,
  backgroundColor: CommonColorCssVariables.TRANSPARENT,
  backgroundOpacity: 100,
  visualEffects: {
    showBottomBorder: true
  }
};

/**
 * Community Breadcrumb Widget
 *
 * @author Luisina Santos, Willi Hyde
 */
const BreadcrumbWidget: WidgetFC<BreadcrumbWidgetProps> = (
  breadcrumbProps: BreadcrumbWidgetProps
) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.BREADCRUMB);
  const { router } = useEndUserRoutes();
  const [, setBreadcrumbPortal] = useGlobalState(GlobalStateType.BREADCRUMB_PORTAL);
  const breadcrumbReference = useRef(null);
  const safeColor = useSafeColor();
  const quiltWrapperId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_ID,
    null
  );
  const templateId: string = router.getUnwrappedQueryParam(
    EndUserQueryParams.QUILT_WRAPPER_TEMPLATE_ID,
    null
  );
  const wrapperId = getQuiltWrapperId(quiltWrapperId, templateId);
  const {
    backgroundColor = defaultBreadcrumbWidgetProps.backgroundColor,
    backgroundOpacity = defaultBreadcrumbWidgetProps.backgroundOpacity,
    linkTextColor = defaultBreadcrumbWidgetProps.linkTextColor,
    linkHighlightColor = defaultBreadcrumbWidgetProps.linkHighlightColor,
    visualEffects = defaultBreadcrumbWidgetProps.visualEffects
  } = breadcrumbProps;

  const defaultBreadcrumbProps: PageHeaderBreadcrumbProps = {
    useTransparentBreadcrumb: false,
    renderBreadcrumbLast: false
  };
  const finalBreadcrumbProps: PageHeaderBreadcrumbProps = Object.assign(
    defaultBreadcrumbProps,
    breadcrumbProps
  );
  const {
    disableLastCrumbForDesktop,
    useParentToNavigateForMobile,
    className: breadcrumbClassName
  } = finalBreadcrumbProps;

  const footerStyles: CSSPropertiesWithVars = {
    '--lia-local-link-color': linkTextColor,
    '--lia-local-link-hover-color': linkHighlightColor,
    '--lia-local-bg-color':
      backgroundColor === CommonColorCssVariables.TRANSPARENT
        ? CommonColorCssVariables.TRANSPARENT
        : safeColor(backgroundColor)
            .alpha(backgroundOpacity / Math.pow(10, 2))
            .toString(),
    '--lia-local-border-bottom': visualEffects?.showBottomBorder
      ? '1px solid var(--lia-bs-border-color)'
      : 'none'
  };

  useEffectOnce(() => setBreadcrumbPortal(breadcrumbReference));

  if (textLoading) {
    return null;
  }

  return (
    <EditableWidget<BreadcrumbWidgetProps>
      className={cx('lia-editor-action-btn')}
      props={breadcrumbProps}
    >
      <nav
        aria-label={formatMessage('navLabel')}
        className={cx('lia-nav', breadcrumbClassName)}
        style={footerStyles}
        data-testid="BreadcrumbWidget"
      >
        <ThemedContainer
          className={cx('d-flex align-items-center')}
          containerRef={breadcrumbReference}
          ignoreXsGutters
        >
          {!!wrapperId ? (
            <BreadcrumbMock
              disableLastCrumbForDesktop={disableLastCrumbForDesktop}
              useParentToNavigateForMobile={useParentToNavigateForMobile}
            />
          ) : (
            <Breadcrumb
              disableLastCrumbForDesktop={disableLastCrumbForDesktop}
              useParentToNavigateForMobile={useParentToNavigateForMobile}
            />
          )}
        </ThemedContainer>
      </nav>
    </EditableWidget>
  );
};

export default BreadcrumbWidget;
